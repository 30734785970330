import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage as Translation } from 'react-intl';
import withRouter from 'HOCs/withRouter';
import cx from 'classnames';

import withTrack from 'HOCs/withTrack/withTrack';

import { getCurrentListingLocations, getCurrentListingLocation } from 'Selectors/popularLocations';
import { getLocations } from 'Actions/locations';
import { isFetchingLocationByParentId } from 'Selectors/location';

import { Modal, Link, Loader } from 'panamera-react-ui';
import css from './TopLocationsModal.scss';
import { css as uiCss } from 'panamera-react-ui';

const { icons } = uiCss;

export class TopLocationsModal extends React.Component {
    static propTypes = {
        onClose: PropTypes.func,
        locations: PropTypes.array.isRequired,
        currentLocation: PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.number,
            type: PropTypes.string
        }).isRequired,
        onSelect: PropTypes.func.isRequired,
        getAllLocations: PropTypes.func.isRequired,
        isFetchingLocations: PropTypes.func.isRequired
    };

    static defaultProps = {
        onClose: () => {},
        currentLocation: {
            id: '',
            name: ''
        }
    };

    constructor() {
        super();

        this.state = {
            inputValue: ''
        };
    }

    componentDidMount() {
        const { currentLocation } = this.props;

        if (currentLocation && currentLocation.id) {
            this.props.getAllLocations(currentLocation.id);
        }
    }

    handleFilter = event => {
        this.setState({
            inputValue: event.target.value
        });
    };

    getTitleData(parentLocation) {
        const titleData = {
            COUNTRY: {
                id: 'allStatesIn'
            },
            STATE: {
                id: 'allCitiesIn'
            },
            CITY: {
                id: 'allNeighborhoodsIn'
            },
            NEIGHBOURHOOD: {
                id: 'moreNeighborhoodsIn'
            }
        };

        const translation = titleData[parentLocation.type] || titleData.COUNTRY;

        return {
            ...translation,
            values: {
                locationName: parentLocation.name
            }
        };
    }
    renderLocationsList() {
        const pattern = new RegExp(this.state.inputValue, 'i');
        const filteredLocations = this.state.inputValue
            ? this.props.locations.filter(location =>
                pattern.test(location.title)
            )
            : this.props.locations;

        return filteredLocations.map(location => {
            const onClick = event => this.props.onSelect(event, location);

            return (
                <Link
                    key={ location.id }
                    className={ css.locationItem }
                    to={ location.to }
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={ onClick }
                >
                    {location.title}
                </Link>
            );
        });
    }

    isLoading = () => {
        const { isFetchingLocations, currentLocation } = this.props;

        return currentLocation
            && currentLocation.id
            && isFetchingLocations(currentLocation.id);
    };

    renderModalContent() {
        const isLoading = this.isLoading();

        return (
            <div className={ css.topLocationsModalContent }>
                { !isLoading && <div className={ css.inputContainer }>
                    <span
                        className={ cx(
                            icons.panameraIcons,
                            css.inputIcon,
                            icons['icon-Search']
                        ) }
                    />
                    <input
                        className={ css.filterInput }
                        onChange={ this.handleFilter }
                    />
                </div> }
                <div className={ css.locationItemContainer }>
                    { isLoading && <div className={ css.loader }><Loader /></div> }
                    { !isLoading && this.renderLocationsList() }
                </div>
            </div>
        );
    }

    render() {
        const title = this.getTitleData(this.props.currentLocation);

        return (
            <Translation id={ title.id } values={ title.values }>
                {text => (
                    <Modal
                        onClick={ this.props.onClose }
                        title={ text }
                        className={ css.topLocationsModal }
                    >
                        {this.renderModalContent()}
                    </Modal>
                )}
            </Translation>
        );
    }
}

const getParams = props => ({
    categoryId: props.params.categoryID,
    locationId: props.params.geoID,
    search: props.params.text,
    params: props.location.query,
    type: props.type || '',
    constructionStatus: props.params.constructionStatus
});

const mapStateToProps = (state, ownProps) => ({
    locations: getCurrentListingLocations(state, getParams(ownProps)),
    currentLocation: getCurrentListingLocation(state, getParams(ownProps)),
    isFetchingLocations: isFetchingLocationByParentId(state)
});

const mapDispatchToProps = dispatch => ({
    getAllLocations: id => dispatch(getLocations({ parent: id }))
});

const decorateComponent = compose(
    withRouter,
    withTrack,
    connect(mapStateToProps, mapDispatchToProps)
);

export default decorateComponent(TopLocationsModal);
