export const trackingMapper = {
    'All Projects': 'ALL_PROJECTS',
    'Newly Launched': 'NEWLY_LAUNCHED',
    'Under Construction': 'UNDER_CONSTRUCTION',
    'Ready To Move': 'READY_TO_MOVE',
    '1 BHK': 'ONE_BHK',
    '2 BHK': 'TWO_BHK',
    '3 BHK': 'THREE_BHK',
    '4 BHK': 'FOUR_BHK',
    '5 BHK': 'FIVE_BHK',
    '6 BHK': 'SIX_BHK',
    '7 BHK': 'SEVEN_BHK',
    '8 BHK': 'EIGHT_BHK'
};

