import React from 'react';
import PropTypes from 'prop-types';
import CarouselImages from './CarouselImages';

import css from './ListingCarousel.APP_TARGET.scss';
const slidesToShow = 3;

class ListingCarousel extends React.PureComponent {
    static propTypes = {
        carouselData: PropTypes.object.isRequired,
        onCarouselClick: PropTypes.func,
        getListingUrl: PropTypes.func
    };

    static defaultProps = {
        onCarouselClick: () => {},
        getListingUrl: () => {}
    };

    render() {
        const { carouselData } = this.props;
        const carouselItems = carouselData && carouselData.tilesInfo || [];
        const sliderSettings = {
            dots: false,
            infinite: carouselItems.length > slidesToShow,
            speed: 500,
            slidesToShow,
            slidesToScroll: slidesToShow,
            arrows: false,
            className: css.carouselContainer
        };

        return (
            <div className={ css.carouselWrapper }>
                <h3 className={ css.heading }>
                    {carouselData.title}
                </h3>
                {carouselData.badgeText && <span className={ css.badge }>{carouselData.badgeText}</span>}
                {carouselData.subTitle && <div className={ css.subHeading }>{carouselData.subTitle}</div>}
                {
                    carouselItems && carouselItems.length > 0
                    && <CarouselImages
                        carouselItems={ carouselItems }
                        sliderSettings={ sliderSettings }
                        onCarouselItemClick={ this.props.onCarouselClick }
                        getListingUrl={ this.props.getListingUrl }
                    />
                }
            </div>
        );
    }
}

export default ListingCarousel;
