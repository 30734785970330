import React from 'react';
import PropTypes from 'prop-types';
import css from './NavigationBarOld.scss';
import CategoriesList from './CategoriesList/CategoriesList';
import TopLocations from '../TopLocations/TopLocations';
import TanakFilters from 'Components/FiltersTanak/Filters.desktop';

const NavigationBarOld = ({
    buildURL,
    location,
    params,
    selectedCategoryId
}) => (
    <div className={ css.navigationBar } data-aut-id="navigationBar">
        <CategoriesList
            location={ location }
            selectedCategoryId={ selectedCategoryId }
            search={ params.text }
        />
        <TopLocations
            pathname={ location.pathname }
            searchPath={ location.search }
            params={ params }
            isFiltersV2
        />
        <TanakFilters
            buildURL={ buildURL }
            categoryID={ selectedCategoryId }
        />
    </div>
);

NavigationBarOld.propTypes = {
    location: PropTypes.object.isRequired,
    params: PropTypes.shape({
        text: PropTypes.string,
        categoryID: PropTypes.string,
        geoID: PropTypes.string
    }).isRequired,
    selectedCategoryId: PropTypes.string.isRequired,
    buildURL: PropTypes.func
};

NavigationBarOld.defaultProps = {
    buildURL: () => {}
};

export default NavigationBarOld;
