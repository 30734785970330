import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import css from './NavigationBarContainer.scss';

const NavigationBarContainer = ({ className, children }) => (
    <div className={ classnames(css.navigationBarContainer, className) } >
        { children }
    </div>
);

NavigationBarContainer.propTypes = {
    className: PropTypes.string,
    children: PropTypes.object.isRequired
};

NavigationBarContainer.defaultProps = {
    className: ''
};

export default NavigationBarContainer;
