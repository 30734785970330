import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withRouter from 'HOCs/withRouter';
import PropTypes from 'prop-types';
import BoxInput from './BoxInput.desktop';
import SliderInput from './SliderInput.desktop';
import { addFiltersRecencyOrder } from 'Actions/filters';
import { getFiltersRecencyOrder } from 'Selectors/filtersTanak';
import { getCategoryById, categoryIdSelector } from 'Selectors/categories';
import { locationByIdSelector } from 'Selectors/location';

import { buildURL } from 'Helpers/url';
import { removeFilter, applyFilter } from 'Helpers/filters';

import withConfig from 'HOCs/withConfig/withConfig';

import { TRACK_FILTER_TYPE } from 'Constants/filters';

import css from './CustomInput.desktop.scss';
import { itemsSearchQuerySelector } from 'Selectors/items';

export class CustomInput extends React.Component {
    static propTypes = {
        range: PropTypes.shape({
            minValue: PropTypes.number.isRequired,
            maxValue: PropTypes.number.isRequired
        }).isRequired,
        label: PropTypes.string.isRequired,
        attribute: PropTypes.string.isRequired,
        selectedValues: PropTypes.shape({
            min: PropTypes.number.isRequired,
            max: PropTypes.number.isRequired
        }),
        selectedFilters: PropTypes.object,
        onRangeChange: PropTypes.func.isRequired,
        isSliderType: PropTypes.bool.isRequired,
        category: PropTypes.object,
        location: PropTypes.object.isRequired,
        geoLocation: PropTypes.object,
        config: PropTypes.object.isRequired,
        filterRecencyOrder: PropTypes.arrayOf(PropTypes.string),
        _addFiltersRecencyOrder: PropTypes.func,
        searchQuery: PropTypes.string,
        histogramData: PropTypes.array
    };

    static defaultProps = {
        selectedFilters: {},
        filterRecencyOrder: [],
        histogramData: []
    }

    handleRangeChange = newRange => {
        const {
            attribute,
            selectedFilters: appliedFiltersInfo,
            category,
            geoLocation: location,
            location: { query },
            searchQuery,
            config,
            onRangeChange,
            filterRecencyOrder,
            _addFiltersRecencyOrder,
            isSliderType
        } = this.props;
        const { min: newMin = '', max: newMax = '' } = newRange;
        const isBlankRange = (newMin === '') && (newMax === '');
        let newFilters;
        const newRecencyOrder = filterRecencyOrder.filter(filterName => filterName !== attribute);

        if (isBlankRange) {
            newFilters = removeFilter(appliedFiltersInfo, attribute);
        }
        else {
            newFilters = applyFilter(appliedFiltersInfo, { [attribute]: newRange });
            newRecencyOrder.unshift(attribute);
        }
        const url = buildURL({
            category,
            location,
            search: searchQuery,
            params: {
                ...query,
                filter: newFilters
            }
        }, { useFakeLevelSlug: config.get('SEO', 'useFakeLevelSlug') });

        _addFiltersRecencyOrder(newRecencyOrder);
        onRangeChange(attribute, newRange, TRACK_FILTER_TYPE.CUSTOM_BOX, null, {
            url,
            newFilters,
            filterAttribute: attribute,
            selectedValue: newRange,
            filterInteractionType: isSliderType ? TRACK_FILTER_TYPE.CUSTOM_SLIDER : TRACK_FILTER_TYPE.CUSTOM_BOX
        });
    }

    render() {
        const {
            attribute,
            selectedValues,
            selectedFilters,
            label,
            range,
            isSliderType,
            histogramData
        } = this.props;

        return (
            <React.Fragment >
                <div className={ css.label }>{label}</div>
                <div className={ css.rangeInput }>
                    {isSliderType ? (
                        <SliderInput
                            attribute={ attribute }
                            selectedValues={ selectedValues }
                            appliedFiltersInfo={ selectedFilters }
                            onRangeChange={ this.handleRangeChange }
                            isSliderType={ isSliderType }
                            range={ range }
                            histogramData={ histogramData }
                        />
                    )
                        : (
                            <BoxInput
                                attribute={ attribute }
                                selectedValues={ selectedValues }
                                appliedFiltersInfo={ selectedFilters }
                                onRangeChange={ this.handleRangeChange }
                                isSliderType={ isSliderType }
                                range={ range }
                            />
                        )
                    }
                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state, props) => {
    const categoryID = categoryIdSelector(state, props.params);

    return {
        geoLocation: locationByIdSelector(state)(props.params.geoID),
        category: getCategoryById(state, categoryID),
        filterRecencyOrder: getFiltersRecencyOrder(state),
        searchQuery: itemsSearchQuerySelector(state, props.params.text)
    };
};

const mapDispatchToProps = dispatch => ({
    _addFiltersRecencyOrder: params => dispatch(addFiltersRecencyOrder(params))
});

export default compose(
    withRouter,
    withConfig,
    connect(mapStateToProps, mapDispatchToProps)
)(CustomInput);
