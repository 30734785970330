import React, { memo, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import PropTypes from 'prop-types';

import { withSchema } from 'HOCs/withSchema/withSchema';

import { DATA_AUT_IDS } from 'Constants/constants';
import { FAQ_TRACK, QUESTION_SEO } from 'Constants/faq';

import CollapsibleV2 from 'Components/CollapsibleV2/CollapsibleV2';

import css from './FAQV2.APP_TARGET.scss';

const markdownPlugins = [gfm];

let FAQ = ({ content, id, title, track }) => {
    const [collapsed, setCollapsed] = useState(new Array(content.length).fill(true));

    useEffect(() => {
        setCollapsed(new Array(content.length).fill(true));
    }, [content]);

    function handleCollapse(toggleCollapsed, index) {
        setCollapsed([...collapsed.slice(0, index), toggleCollapsed, ...collapsed.slice(index + 1)]);
    }

    function handleTrack(open) {
        if (open) {
            track(FAQ_TRACK.FAQ_CLICKED, { category_id: id });
        }
    }

    return (
        <div className={ css.contentContainer } data-aut-id="faq-container">
            <div className={ css.headerTitle } data-aut-id={ DATA_AUT_IDS.FAQ_HEADER_TITLE }>{title}</div>

            <div className={ css.contentWrapper }>
                {content.map(({ a, q, list }, idx) => {
                    function onCollapsed(toggleCollapsed) {
                        handleCollapse(toggleCollapsed, idx);
                    }

                    return (
                        <div itemScope itemProp={ QUESTION_SEO.ITEM_PROP } itemType={ QUESTION_SEO.ITEM_TYPE } key={ `faq_${idx}` }>
                            <CollapsibleV2
                                title={ q }
                                collapsed={ collapsed[idx] }
                                onCollapsed={ onCollapsed }
                                key={ `faq_${idx}` }
                                data-aut-id={ `faq_q${idx}` }
                                titleClassName={ css.title }
                                className={ css.collapsible }
                                isClassified={ true }
                                onClick={ handleTrack }
                            >
                                <div className={ css.content }>
                                    <ReactMarkdown remarkPlugins={ markdownPlugins }>{a}</ReactMarkdown>
                                    {list ? <ReactMarkdown remarkPlugins={ markdownPlugins }>{`- ${ list.join('\n\n - ')}`}</ReactMarkdown> : undefined}
                                </div>
                            </CollapsibleV2>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

FAQ.propTypes = {
    content: PropTypes.arrayOf({
        q: PropTypes.string,
        a: PropTypes.string,
        list: PropTypes.arrayOf(PropTypes.string)
    }),
    id: PropTypes.string,
    title: PropTypes.string,
    track: PropTypes.func
};

FAQ.defaultProps = {
    content: [],
    title: 'FAQs'
};

FAQ = memo(withSchema('FAQPage')(FAQ));
export default FAQ;
