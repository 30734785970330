import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage as Translation } from 'react-intl';

import Button from 'Components/Button/Button';
import { getLocale } from 'Selectors/config';
import { getFormattedAmount, parseLocaleInteger } from 'Helpers/numbers';
import withConfig from 'HOCs/withConfig/withConfig';

import { BACKSPACE_KEY, NUMERIC_ZERO_KEY, NUMERIC_NINE_KEY } from 'Constants/keyStrokes';

import css from './BoxInput.desktop.scss';
import { ATTR_WITH_MAX_LIMIT } from 'Constants/filters';

export class BoxInput extends React.PureComponent {
    static propTypes = {
        range: PropTypes.shape({
            minValue: PropTypes.number.isRequired,
            maxValue: PropTypes.number.isRequired,
            minLabel: PropTypes.string.isRequired,
            maxLabel: PropTypes.string.isRequired
        }).isRequired,
        attribute: PropTypes.string.isRequired,
        selectedValues: PropTypes.shape({
            min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
        }),
        onRangeChange: PropTypes.func.isRequired,
        config: PropTypes.object.isRequired,
        currentLocale: PropTypes.string
    }

    static defaultProps = {
        selectedValues: { min: '', max: '' }
    }

    constructor(props) {
        super(props);
        const { min, max } = props.selectedValues;

        this.state = {
            max: max || '',
            min: min || ''
        };

        const { attribute, config } = props;

        this.showPriceSeparator = attribute === 'price' && config.get('listing', 'showPriceSeparatorInFilterInput');
    }

    componentDidUpdate(prevProps, prevState) {
        const { min, max } = this.props.selectedValues;
        const { min: stateMin, max: stateMax } = this.state;
        // eslint-disable-next-line react/prop-types
        const isMinMaxPropsChanged = (min !== prevProps.min || max !== prevProps.max);
        const isMinMaxStateChanged = (stateMin !== prevState.min || stateMax !== prevState.max);

        if (isMinMaxPropsChanged && !isMinMaxStateChanged) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                max: max || '',
                min: min || ''
            });
        }
    }

    handleMinValueChange = evt => {
        const parsedVal = parseLocaleInteger(evt.target.value, this.props.currentLocale);
        const min = Math.round(Math.abs(parsedVal));

        evt.target.value = min;

        this.setState({ min: min || '' });
    }

    handleMaxValueChange = evt => {
        const { range } = this.props;
        const parsedVal = parseLocaleInteger(evt.target.value, this.props.currentLocale);
        const max = Math.round(Math.abs(parsedVal));

        evt.target.value = max;

        if (max && max <= range?.maxValue) {
            this.setState({ max });
        }
        else {
            this.setState({ max: '' });
        }
    }

    handleKeyPress = evt => {
        const charCode = (evt.which) ? evt.which : evt.keyCode;

        if (!(charCode === BACKSPACE_KEY || (charCode >= NUMERIC_ZERO_KEY && charCode <= NUMERIC_NINE_KEY))) {
            evt.preventDefault();
        }
    }

    handleApply = () => {
        const { onRangeChange, attribute, range: {
            minValue,
            maxValue
        }} = this.props;
        const { min, max } = this.state;
        const newRange = {};

        if (min !== '') {
            newRange.min = min;
        }

        if (max !== '') {
            newRange.max = max;
        }

        if (ATTR_WITH_MAX_LIMIT[attribute]) {
            newRange.min = min === '' ? minValue : newRange.min;
            newRange.max = max === '' ? maxValue : newRange.max;
        }

        onRangeChange(newRange);
    };

    shouldBtnBeDisabled = () => {
        const { selectedValues = {}, range } = this.props;
        const { min: selectedMin, max: selectedMax } = selectedValues;
        const { minValue, maxValue } = range;
        const { min, max } = this.state;

        const isMinUpdated = min !== (selectedMin || minValue);
        const isMaxUpdated = max !== (selectedMax || maxValue);
        const areBothZeroValue = (min === minValue && max === minValue) || (min === '' && max === '');

        return !(isMaxUpdated || isMinUpdated) || areBothZeroValue;
    };

    render() {
        const {
            range: {
                minLabel,
                maxLabel
            },
            attribute
        } = this.props;

        const { min, max } = this.state;
        const selectedMinValue = this.showPriceSeparator ? getFormattedAmount(min, null, this.props.currentLocale) : min;
        const selectedMaxValue = this.showPriceSeparator ? getFormattedAmount(max, null, this.props.currentLocale) : max;

        return (
            <div className={ css.boxInput }>
                <div className={ css.boxInput__range }>
                    <input
                        className={ css.boxInput__inputField }
                        key={ `${attribute}-min` }
                        type="text"
                        onKeyDown={ this.handleKeyPress }
                        onChange={ this.handleMinValueChange }
                        value={ selectedMinValue || '' }
                        placeholder={ minLabel }
                    />
                    <Translation id="to" />
                    <input
                        className={ css.boxInput__inputField }
                        key={ `${attribute}-max` }
                        type="text"
                        onKeyDown={ this.handleKeyPress }
                        onChange={ this.handleMaxValueChange }
                        value={ selectedMaxValue || '' }
                        placeholder={ maxLabel }
                    />
                </div>
                <Button
                    className={ css.applyBtn }
                    type="primary"
                    onClick={ this.handleApply }
                    disabled={ this.shouldBtnBeDisabled() }
                    data-aut-id="apply-filter-btn"
                >
                    <Translation id="boostPackageAppy" />
                </Button>
            </div>
        );
    }
}

export const mapStateToProps = state => {
    return {
        currentLocale: getLocale(state)
    };
};

export default compose(
    withConfig,
    connect(mapStateToProps)
)(BoxInput);
