import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withRouter from 'HOCs/withRouter';

import withConfig from 'HOCs/withConfig/withConfig';
import withTrack from 'HOCs/withTrack/withTrack';
import withLocation from 'HOCs/withLocation/withLocation';

import { get } from 'Server';
import { baseUrl } from 'Reducers/projects';
import trackingEvents from 'Constants/trackingEvents';
import * as objectsHelper from 'Helpers/objects';

import { trackingMapper } from './carouselHelper';
import ListingCarousel from './ListingCarousel.APP_TARGET';
import { slugify } from 'Helpers/strings';
const STR_LEN = 5;

import { setCarouselLocation } from 'Actions/projects';

export class ListingCarouselWrapper extends React.Component {
    static propTypes = {
        getCarouselData: PropTypes.func,
        carouselData: PropTypes.object,
        selectedLocation: PropTypes.object,
        track: PropTypes.func,
        updateLocation: PropTypes.func,
        onLocationChange: PropTypes.func,
        setCarouselLocation: PropTypes.func,
        carouselLocationId: PropTypes.number,
        locationById: PropTypes.func,
        trackerOrigins: PropTypes.object
    };

    static defaultProps = {
        getCarouselData: () => {},
        track: () => {},
        carouselData: {},
        selectedLocation: {},
        updateLocation: () => {},
        onLocationChange: () => {},
        setCarouselLocation: () => {},
        locationById: () => {},
        trackerOrigins: {}
    };

    componentDidMount() {
        const { selectedLocation } = this.props;
        const locationId = selectedLocation && selectedLocation.id;

        if (locationId && objectsHelper.isEmpty(this.props.carouselData)) {
            this.props.setCarouselLocation({ locationId });
            this.props.getCarouselData({ locationId }, res => this.trackProjectEntryLoad(res));
        }
    }

    componentDidUpdate(prevProps) {
        let locationId;
        const prevPropsLocationId = prevProps.selectedLocation && prevProps.selectedLocation.id;
        const currentPropsLocationId = this.props.selectedLocation && this.props.selectedLocation.id;

        if (prevPropsLocationId !== currentPropsLocationId || this.props.carouselLocationId !== currentPropsLocationId) {
            locationId = currentPropsLocationId;
            this.props.setCarouselLocation({ locationId });
        }

        if (locationId) {
            this.props.getCarouselData({ locationId }, res => this.trackProjectEntryLoad(res));
        }
    }

    onCarouselClick = item => {
        const locationId = item && item.filters && item.filters.locationId;
        const { selectedLocation } = this.props;
        const carouselLabel = trackingMapper[item && item.label];

        if (locationId && locationId !== selectedLocation.id) {
            this.props.updateLocation(locationId);
            this.props.onLocationChange('', '', locationId, true);
        }
        this.props.track(trackingEvents.reTapProjectsEntry, {
            origin: this.props.trackerOrigins[carouselLabel],
            search_string: this.props.selectedLocation && this.props.selectedLocation.name
        });
    };
    getListingUrl = filters => {
        let url = '/projects';

        if (filters.locationId) {
            const location = this.props.locationById(filters.locationId);

            url = `${url}/${slugify(location.name)}_g${location.id}`;
        }
        const status = filters.constructionStatus;

        return status ? `${url}/${slugify(status)}` : url;
    };
    trackProjectEntryLoad(res) {
        if (res.ok && res.data) {
            const data = res.data.data;

            this.props.track(trackingEvents.reLoadProjectsEntry, {
                chosen_option: data && data.title,
                resultset_id: data && this.getResultCount(data.tilesInfo)
            });
        }
    }
    getResultCount(tiles = []) {
        let result = '';

        tiles.forEach((tile, i) => {
            result += tile && `${i === 0 ? '' : ','} ${tile.label.toLowerCase().substring(0, STR_LEN)}:${tile.count}`;
        });
        return result;
    }

    render() {
        const { carouselData } = this.props;

        if (!carouselData || !(carouselData.tilesInfo && carouselData.tilesInfo.length > 0)) {
            return null;
        }

        return (
            <ListingCarousel
                carouselData={ carouselData }
                onCarouselClick={ this.onCarouselClick }
                locationById={ this.props.locationById }
                getListingUrl={ this.getListingUrl }
            />
        );
    }
}

export const mapStateToProps = state => {
    return {
        selectedLocation: state.locations.selectedLocation,
        carouselData: state.projects && state.projects.carouselData && state.projects.carouselData.data,
        carouselLocationId: state.projects && state.projects.carouselData && state.projects.carouselData.locationId
    };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setCarouselLocation: params => dispatch(setCarouselLocation(params)),
        getCarouselData: (params, cb = () => {}) => dispatch(get(`${baseUrl}${ownProps.carouselApiRequestPath}`, 'PROJECT_CAROUSEL_DATA', params)).then(res => cb(res))
    };
};

export default compose(
    withConfig,
    withTrack,
    withRouter,
    withLocation,
    connect(mapStateToProps, mapDispatchToProps)
)(ListingCarouselWrapper);
