import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { FormattedMessage as Translation } from 'react-intl';

import CarouselArrows from './CarouselArrows';

import css from './ListingCarousel.APP_TARGET.scss';

export class CarouselImages extends React.Component {
    static propTypes = {
        carouselItems: PropTypes.array,
        sliderSettings: PropTypes.object,
        onCarouselItemClick: PropTypes.func,
        getListingUrl: PropTypes.func
    };

    static defaultProps = {
        carouselItems: [],
        sliderSettings: {},
        onCarouselItemClick: () => {},
        getListingUrl: () => {}
    };

    next = () => this.slider.slickNext();

    previous = () => this.slider.slickPrev();

    onCarouselItemClick = item => () => {
        this.props.onCarouselItemClick(item);
    };
    render() {
        const { carouselItems, sliderSettings } = this.props;

        return (
            <React.Fragment>
                <Slider ref={ slider => (this.slider = slider) } { ...sliderSettings }>
                    {
                        carouselItems.map((item, index) => {
                            return (
                                <Link to={ this.props.getListingUrl(item.filters) } onClick={ this.onCarouselItemClick(item) } key={ index }>
                                    <div className={ css.singleItemWrapper }>
                                        <img src={ item.imageUrl } className={ css.imageStyle } />
                                        <div className={ css.imageTextWrapper }>
                                            <span className={ css.title }>{ item.label }</span>
                                            <span className={ css.countLabel }>{ `${item.count}` }</span>
                                            <span className={ css.countText }><Translation id="projects" /></span>
                                        </div>
                                    </div>
                                </Link>
                            );
                        })
                    }
                </Slider>
                {
                    carouselItems && carouselItems.length > sliderSettings.slidesToShow
                    && <CarouselArrows
                        prevClick={ this.previous }
                        nextClick={ this.next }
                    />
                }
            </React.Fragment>
        );
    }
}

export default CarouselImages;

