import React from 'react';
import css from './NavigationBarPlaceholder.scss';

const NavigationBarPlaceholder = () => {
    return (
        <div className={ css.navigationBar }>
            <div className={ css.title } />
            <div className={ css.filter } >
                <div className={ css.subTitle } />
                <div className={ css.text } />
            </div>
            <div className={ css.filter } >
                <div className={ css.subTitle } />
                <div className={ css.text } />
            </div>
            <div className={ css.filter } >
                <div className={ css.subTitle } />
                <div className={ css.text } />
            </div>
            <div className={ css.filter } >
                <div className={ css.subTitle } />
                <div className={ css.text } />
            </div>
            <div className={ css.filter } >
                <div className={ css.subTitle } />
                <div className={ css.text } />
            </div>
            <div className={ css.filter } >
                <div className={ css.subTitle } />
                <div className={ css.text } />
            </div>
        </div>
    );
};

export default NavigationBarPlaceholder;
