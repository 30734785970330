import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from 'Components/ThematicIcon/ThematicIcon';
import css from './SearchInput.scss';
import IconWrapper from 'Components/IconWrapper/IconWrapper';

export const SearchInput = ({ placeholder, searchValue, onSearchEmpty, onFocus, onBlur, className, setSearchCriteria, isMX }) => {
    const handleChange = useCallback(e => {
        setSearchCriteria(e.target.value);
    }, [setSearchCriteria]);

    return (
        <div className={ cx(css.search, className) }>
            <div className={ cx(css.searchInputWrapper, { [css.mxSearchInputWrapper]: isMX }) }>
                <input
                    type="text"
                    placeholder={ placeholder }
                    onChange={ handleChange }
                    value={ searchValue }
                    onFocus={ onFocus }
                    onBlur={ onBlur }
                />
                <span className={ css.searchIcon } >
                    {
                        searchValue
                            ? <IconWrapper icon="cross" onClick={ onSearchEmpty } color="blackIcons" size={ 20 } />
                            : <Icon icon="search" color="blackSecondaryText" size={ 20 } />
                    }
                </span>
            </div>
        </div>
    );
};

SearchInput.propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    searchValue: PropTypes.string.isRequired,
    setSearchCriteria: PropTypes.func.isRequired,
    onSearchEmpty: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    isMX: PropTypes.bool
};

SearchInput.defaultProps = {
    className: '',
    onFocus: () => {},
    onBlur: () => {},
    isMX: false
};

export default SearchInput;
