import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import css from './CollapsibleV2.scss';
import Icon from 'Components/ThematicIcon/ThematicIcon';
import { ANSWER_SEO, QUESTION_SEO } from 'Constants/faq';

export const CollapsibleV2 = React.forwardRef(({
    title,
    titleClassName,
    isInitiallyOpen,
    children,
    collapsedContent,
    collapsedContentClassName,
    collapsedTitleClassName,
    className,
    onClick,
    arrowSize,
    contentClassName,
    'data-aut-id': dataAutId,
    isClassified
}, ref) => {
    const [isContentOpen, setContentState] = useState(() => !!isInitiallyOpen);

    const toggleContent = useCallback(() => {
        setContentState(!isContentOpen);
        onClick(!isContentOpen);
    }, [isContentOpen, onClick]);

    return (
        <div data-aut-id={ dataAutId } className={ cx(css.collapsible, className) } ref={ ref }>
            <div data-aut-id="collapsibleAction"
                className={ cx(css.title, titleClassName, {
                    [collapsedTitleClassName]: !isContentOpen
                }) }
                onClick={ toggleContent }>
                <span itemProp={ QUESTION_SEO.ITEM_PROP }>{title}</span>
                <Icon
                    icon={ isContentOpen ? 'arrowUp' : 'arrowDown' }
                    size={ arrowSize }
                />
            </div>
            { (isContentOpen || isClassified) && <div className={ cx(css.content, contentClassName, { [css.collapsed]: !isContentOpen }) }>
                <div itemScope itemProp={ ANSWER_SEO.ITEM_PROP } itemType={ ANSWER_SEO.ITEM_TYPE }>
                    <div itemProp="text">
                        {children}
                    </div>
                </div>
            </div>}
            { !isContentOpen && collapsedContent && <div className={ cx(css.collapsedContent, collapsedContentClassName) }>
                { collapsedContent }
            </div>}
        </div>
    );
});

CollapsibleV2.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]).isRequired,
    className: PropTypes.string,
    titleClassName: PropTypes.string,
    onClick: PropTypes.func,
    'data-aut-id': PropTypes.string,
    isInitiallyOpen: PropTypes.bool,
    collapsedContent: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    collapsedContentClassName: PropTypes.string,
    collapsedTitleClassName: PropTypes.string,
    arrowSize: PropTypes.number,
    contentClassName: PropTypes.string,
    isClassified: PropTypes.bool
};

CollapsibleV2.defaultProps = {
    'data-aut-id': 'collapsible',
    className: '',
    titleClassName: '',
    collapsedContent: null,
    onClick: () => { },
    isFiltersV2: false,
    isInitiallyOpen: false,
    collapsedContentClassName: '',
    collapsedTitleClassName: '',
    arrowSize: 16,
    isClassified: false
};

CollapsibleV2.displayName = 'CollapsibleV2';

export default CollapsibleV2;
