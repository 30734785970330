import PropTypes from 'prop-types';
import React from 'react';
import css from './CategoriesList.scss';
import { connect } from 'react-redux';
import * as urlHelper from 'Helpers/url';
import { Link } from 'panamera-react-ui';
import { FormattedMessage as Translation } from 'react-intl';
import { resetFilters, setFilters } from 'Actions/track';
import Collapsible from 'Components/Collapsible/Collapsible';
import { categoriesByFilterSelector } from 'Selectors/categories';
import { getCategories } from 'Actions/categories';
import { addFilterPreference } from 'Actions/filtersPreferences';
import { getFiltersPreferences } from 'Selectors/filtersPreferences';
import { compose } from 'redux';
import withTrack from 'HOCs/withTrack/withTrack';
import withConfig from 'HOCs/withConfig/withConfig';
import cx from 'classnames';
import { css as uiCss } from 'panamera-react-ui';
import ConfirmModal from 'Components/ConfirmModal/ConfirmModal';
import withRouter from 'HOCs/withRouter';
import { formatPrice } from 'Helpers/numbers';
import { getLocale } from 'Selectors/config';
import { SELECT_FROM, CHOSEN_OPTIONS, CATEGORY_CHANGE, TAP_ALLCATEGORIES, BROWSE_CATEGORY_SELECT, BROWSE_SUBCATEGORY_SELECT } from 'Constants/tracking';
const { customScrollbar: extraCss } = uiCss;
const FILTER_CATEGORY_LIST = 'FILTER_CATEGORY_LIST';

export class CategoriesList extends React.PureComponent {
    static propTypes = {
        track: PropTypes.func,
        getCategories: PropTypes.func.isRequired,
        categories: PropTypes.array.isRequired,
        location: PropTypes.object,
        selectedCategoryId: PropTypes.string,
        search: PropTypes.string,
        resetFiltersTrack: PropTypes.func,
        setFiltersTrack: PropTypes.func,
        trackOrigin: PropTypes.string,
        flatCategoryMap: PropTypes.object.isRequired,
        addFilterPreference: PropTypes.func.isRequired,
        filtersPreferences: PropTypes.object,
        config: PropTypes.shape({
            get: PropTypes.func.isRequired
        }).isRequired,
        router: PropTypes.object.isRequired,
        locale: PropTypes.string.isRequired
    };

    static defaultProps = {
        location: {},
        resetFiltersTrack: () => {},
        setFiltersTrack: () => {},
        trackOrigin: '',
        filtersPreferences: {}
    };

    constructor(props) {
        super(props);
        this.translations = {
            allCategories: <Translation id="allCategories" />,
            categories: <Translation id="categories" />,
            title: <Translation id={ 'changeCategoryTitle' } defaultMessage="Changing category will clear all filter selections" />,
            acceptText: <Translation id={ 'terms_accept' } defaultMessage="Continue" />,
            declineText: <Translation id={ 'changeCategoryCancel' } defaultMessage="Go Back" />
        };
        this.categoryClicked = null;
        this.state = {
            showCategoryChangeModal: false
        };
    }

    componentDidMount() {
        this.props.getCategories();
    }

    onClickCategory = (categoryId, eventName) => {
        this.props.resetFiltersTrack().then(() => {
            this.props.setFiltersTrack({ category: categoryId });
        });
        this.props.track(eventName, {
            category_id: categoryId,
            select_from: SELECT_FROM.FILTER_PAGE,
            origin: this.props.trackOrigin
        });
    };

    onModalResponse = (categoryId, changed = true) => {
        this.props.track(CATEGORY_CHANGE, {
            category_id: categoryId,
            select_from: SELECT_FROM.FILTER_PAGE,
            origin: this.props.trackOrigin,
            chosen_option: changed ? CHOSEN_OPTIONS.CHANGE : CHOSEN_OPTIONS.CANCEL
        });
    }

    onClickSeeAll = event => {
        const { resetFiltersTrack, selectedCategoryId, location } = this.props;
        const { query: { filter: isFiltersApplied } = {}} = location;

        this.onClickCategory(null, TAP_ALLCATEGORIES);

        if (isFiltersApplied) {
            if (selectedCategoryId) {
                this.setState({ showCategoryChangeModal: true });
            }
            event.preventDefault();
        }
        else {
            resetFiltersTrack();
        }
    };

    handleOnCollapsed = collapsed => this.props.addFilterPreference({ [FILTER_CATEGORY_LIST]: { collapsed }})

    buildURL(category) {
        const { config } = this.props;
        const { pathname } = this.props.location;
        const urlParams = urlHelper.buildObjectFromURL(pathname);

        return urlHelper.buildURL({
            location: urlParams.location,
            search: urlParams.search,
            category
        }, { categoryCover: config.get('categoryCover') });
    }

    setShowCategoryChangeModal = category => {
        const { selectedCategoryId } = this.props;

        if (category.id !== selectedCategoryId) {
            this.setState({ showCategoryChangeModal: true });
            this.categoryClicked = category;
        }
    }

    renderSubCategories(category = {}) {
        const { selectedCategoryId, locale, location, config } = this.props;
        const { noindexListingCategories } = config.get('SEO');
        const hiddenCategory = (noindexListingCategories && noindexListingCategories.length > 0 && category.id && noindexListingCategories.findIndex(element => element == category.id) >= 0);
        const { query: { filter: isFiltersApplied } = {}} = location;
        const onCategoryClick = event => {
            this.onClickCategory(category.id, BROWSE_CATEGORY_SELECT);
            if (isFiltersApplied) {
                this.setShowCategoryChangeModal(category);
                event.preventDefault();
            }
        };

        const classList = cx(css.menu, css.tanakMenu, extraCss.customScrollbar);

        return (
            <ul className={ classList } data-aut-id="subcategories">
                <li key={ 0 }>
                    <Link
                        to={ this.buildURL() }
                        rel={ this.props.search ? 'nofollow' : '' }
                        className={ cx(css.link, css.allCategories, css.tanakAllCategories) }
                        activeClassName={ css.active }
                        onClick={ this.onClickSeeAll }
                    >
                        <div className={ css.container }>
                            <div className={ css.hyphen } />
                            <Translation id="allCategories" />
                        </div>
                    </Link>
                </li>
                { category.name
                    && <li key={ 1 }>
                        { !hiddenCategory && <Link
                            to={ this.buildURL(category) }
                            rel={ this.props.search ? 'nofollow' : '' }
                            className={ cx(css.link, css.tanakCurrentParentCategory, {
                                [css.highlighted]: (category.id === selectedCategoryId),
                                [css.selected]: category.id === selectedCategoryId
                            }) }
                            activeClassName={ css.active }
                            // eslint-disable-next-line react/jsx-no-bind
                            onClick={ onCategoryClick }
                            data-aut-id={ `category_${category.id}` }
                        >
                            <div className={ css.container }>
                                <div className={ css.hyphen } />
                                { category.name }
                            </div>
                        </Link>}
                        <ul className={ css.menu }>
                            { category.sub_categories && category.sub_categories.map(subCategory => {
                                const hiddenSubCategory = (noindexListingCategories && noindexListingCategories.length > 0 && subCategory.id && noindexListingCategories.findIndex(element => element == subCategory.id) >= 0);
                                const onSubcategoryClick = event => {
                                    this.onClickCategory(subCategory.id, BROWSE_SUBCATEGORY_SELECT);
                                    if (isFiltersApplied) {
                                        this.setShowCategoryChangeModal(subCategory);
                                        event.preventDefault();
                                    }
                                };

                                return (!hiddenSubCategory
                                    && <li className={ css.subCategory } key={ subCategory.id }>
                                        <Link
                                            to={ this.buildURL(subCategory) }
                                            rel={ this.props.search ? 'nofollow' : '' }
                                            className={ cx(css.link, css.tanakLink, {
                                                [css.highlighted]: subCategory.id === selectedCategoryId,
                                                [css.selected]: (subCategory.id === selectedCategoryId)
                                            }) }
                                            activeClassName={ css.active }
                                            // eslint-disable-next-line react/jsx-no-bind
                                            onClick={ onSubcategoryClick }
                                            data-aut-id={ `subcategory_${subCategory.id}` }
                                        >
                                            <span>{ subCategory.name }</span>
                                            { subCategory.count
                                                && <span className={ cx(css.count, css.tanakCount) }>
                                                    {` (${ formatPrice(subCategory.count, locale) })`
                                                    }
                                                </span> }
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                }
            </ul>
        );
    }

    renderCategories(categories) {
        const { locale, location } = this.props;
        const { query: { filter: isFiltersApplied } = {}} = location;
        const classList = cx(css.menu, css.tanakMenu, extraCss.customScrollbar);

        return (
            <ul
                className={ classList }
                data-aut-id="categories"
            >
                <li key={ 0 } className={ `${css.link} ${ css.highlighted } ${css.allCategories}` } onClick={ this.onClickSeeAll }>
                    <div className={ css.container }>
                        <div className={ css.hyphen } />
                        <Translation id="allCategories" />
                    </div>
                </li>
                <li key={ 1 }>
                    <ul className={ css.menu }>
                        { categories.map(category => {
                            const onClick = event => {
                                this.onClickCategory(category.id, BROWSE_CATEGORY_SELECT);
                                if (isFiltersApplied) {
                                    this.setShowCategoryChangeModal(category);
                                    event.preventDefault();
                                }
                            };

                            return (
                                <li key={ category.id } className={ css.subCategory }>
                                    <Link
                                        to={ this.buildURL(category) }
                                        rel={ this.props.search ? 'nofollow' : '' }
                                        className={ css.link }
                                        // eslint-disable-next-line react/jsx-no-bind
                                        onClick={ onClick }
                                        data-aut-id={ `category_${category.id}` }
                                    >
                                        <span>{ category.name }</span>
                                        { category.count
                                        && <span className={ cx(css.count, css.tanakCount) }>
                                            {` (${ formatPrice(category.count, locale)})`}
                                        </span> }
                                    </Link>
                                </li>
                            );
                        }) }
                    </ul>
                </li>
            </ul>
        );
    }

    searchCategory(categoriesTree, categoryId) {
        let foundCategory;

        categoriesTree.sub_categories.forEach(category => {
            if (category.id === categoryId) {
                if (category.sub_categories) {
                    foundCategory = category;
                }
                else {
                    foundCategory = categoriesTree;
                }
            }
            else if (category.sub_categories) {
                foundCategory = this.searchCategory(category, categoryId) || foundCategory;
            }
        });

        return foundCategory;
    }

    onAccept = () => {
        const { router } = this.props;

        this.setState({ showCategoryChangeModal: false });
        this.onModalResponse(this.categoryClicked && this.categoryClicked.id, true);

        if (this.categoryClicked) {
            router.push(this.buildURL(this.categoryClicked));
        }
        else {
            this.props.resetFiltersTrack();
            router.push(this.buildURL());
        }

        this.categoryClicked = null;
    }

    onDecline = () => {
        this.setState({ showCategoryChangeModal: false });
        this.onModalResponse(this.categoryClicked && this.categoryClicked.id, false);
        this.categoryClicked = null;
    }

    render() {
        const { selectedCategoryId, flatCategoryMap, filtersPreferences, categories } = this.props;
        const preference = filtersPreferences[FILTER_CATEGORY_LIST];
        let currentCategory = this.translations.allCategories;

        if (selectedCategoryId && flatCategoryMap && flatCategoryMap[selectedCategoryId]) {
            currentCategory = flatCategoryMap[selectedCategoryId].name;
        }

        let categoriesList;

        if (selectedCategoryId) {
            categoriesList = this.renderSubCategories(
                this.searchCategory({ sub_categories: categories },
                    selectedCategoryId
                ));
        }
        else {
            categoriesList = this.renderCategories(categories);
        }

        return (
            <React.Fragment>
                <Collapsible
                    title={ this.translations.categories }
                    className={ cx(css.categoriesList, css.filterV2) }
                    collapsedContent={ currentCategory }
                    collapsed={ preference && preference.collapsed }
                    onCollapsed={ this.handleOnCollapsed }
                    data-aut-id="collapsible_categoriesList"
                    isFiltersV2
                >
                    { categoriesList }
                </Collapsible>
                <ConfirmModal
                    isOpened={ this.state.showCategoryChangeModal }
                    title={ this.translations.title }
                    acceptText={ this.translations.acceptText }
                    declineText={ this.translations.declineText }
                    onAccept={ this.onAccept }
                    onDecline={ this.onDecline }
                    titleAlign={ 'center' }
                    reverseBtnOrder
                    titleWordBreak={ '' }
                />
            </React.Fragment>
        );
    }
}

export const mapStateToProps = state => ({
    categories: categoriesByFilterSelector(state),
    trackOrigin: state.track.origin,
    flatCategoryMap: state.categories.elements,
    filtersPreferences: getFiltersPreferences(state),
    locale: getLocale(state)
});

export const mapDispatchToProps = dispatch => ({
    getCategories,
    resetFiltersTrack: () => dispatch(resetFilters()),
    setFiltersTrack: filters => dispatch(setFilters(filters)),
    addFilterPreference: filterPreference => dispatch(addFilterPreference(filterPreference))
});

export default compose(
    withTrack,
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withConfig
)(CategoriesList);
