import React, { memo, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import withRouter from 'HOCs/withRouter';
import withTrack from 'HOCs/withTrack/withTrack';

import { getFaq } from 'Actions/faq';

import { categoriesByFilterSelector } from 'Selectors/categories';
import { getAbundanceFilterInfo, getAppliedFiltersLabels, getParsedAppliedFilters } from 'Selectors/filtersTanak';
import { getPopularLocationsLeftBar } from 'Selectors/popularLocations';

import { getFilteredNestedSelect } from 'Helpers/filtersTanak';
import { MAIN_CATEGORY } from 'Constants/categories';
import { FAQ_TRACK, FAQS } from 'Constants/faq';

import {
    getContentForBikesCategory,
    getContentForCarsCategory,
    getContentForElectronicsCategory,
    getContentForJobsCategory,
    getContentForMobilesCategory,
    getContentForRealEstateCategory,
    getFaqDynamicValues,
    getMinimumPrice,
    getMultipleFilterSelected,
    getPlotSize,
    getTransformedMakeModels,
    interpolate,
    interpolateContent
} from './FAQ.helpers';
import FAQ from './FAQ';
import withSessionFeature from 'HOCs/withSessionFeature/withSessionFeature';

const FAQWrapperV2 = ({
    category,
    faqContent,
    getFaqContents,
    parentCategoryId,
    track,
    ...restProps
}) => {
    const isMultipleFilterSelected = getMultipleFilterSelected(restProps?.selectedFilters || []);
    const flag = (restProps?.sessionFeatures || []).includes('olxin-4485');

    function getTypesAndContent(id) {
        switch (id) {
            case MAIN_CATEGORY.BIKES:
                return getContentForBikesCategory(category.id, restProps);
            case MAIN_CATEGORY.ELECTRONICS:
                return getContentForElectronicsCategory(category, parentCategoryId, restProps);
            case MAIN_CATEGORY.JOBS:
                return getContentForJobsCategory(category, parentCategoryId, restProps);
            case MAIN_CATEGORY.REAL_ESTATE:
                return getContentForRealEstateCategory(category, parentCategoryId, restProps);
            case MAIN_CATEGORY.MOBILES:
                return getContentForMobilesCategory(category, parentCategoryId, restProps);
            case MAIN_CATEGORY.CAR:
                return getContentForCarsCategory({ params: restProps.params, selectedFilters: restProps.selectedFilters });
            default: return { content: {}, type: FAQS.NOOP };
        }
    }

    useEffect(() => {
        track(FAQ_TRACK.LISTING_RESULTS, { faq_available: true });
        track(FAQ_TRACK.FAQ_DISPLAYED, { category_id: category?.id });

        if (restProps.selectedLocation && restProps?.locations?.length > 0) {
            const payload = (flag ? getFaqDynamicValues(category, parentCategoryId, restProps) : getTypesAndContent(parentCategoryId)) || {};

            if (payload.type) {
                getFaqContents(payload.type, payload.content);
            }
        }
    }, [restProps.selectedLocation?.id, restProps?.locations?.length]); // eslint-disable-line react-hooks/exhaustive-deps

    const haveSelectedFilters = restProps?.selectedFilters?.length;

    useEffect(() => {
        if (restProps?.selectedFilters?.length > 0) {
            const payload = (flag ? getFaqDynamicValues(category, parentCategoryId, restProps) : getTypesAndContent(parentCategoryId)) || {};

            if (payload.type && !isMultipleFilterSelected) {
                // need to interpolate on FE, instead of BE.
                delete payload?.content?.top_models;
                getFaqContents(payload?.type, payload?.content);
            }
        }
    }, [haveSelectedFilters]); // eslint-disable-line react-hooks/exhaustive-deps

    let title = '';
    let content = [];

    if (flag) {
        const payload = interpolateContent(faqContent, restProps, () => getFaqDynamicValues(category, parentCategoryId, restProps));

        title = payload.title;
        content = payload.content;
    }
    else if (faqContent?.title && faqContent?.content?.length > 0) {
        title = interpolate(faqContent?.title, restProps.selectedLocation?.name, getTypesAndContent(parentCategoryId)?.content);

        for (const c of (faqContent?.content || [])) {
            const typeContent = getTypesAndContent(parentCategoryId);
            const payload = typeContent?.content;
            const regex = new RegExp(/\{(top_models|popular_areas|job_types|number_of_bikes|number_of_scooters|number_of_bicycles|number_of_items|number|models)\}/, 'g');

            if (regex.test(c.a) && typeContent?.type) {
                if (
                    ('top_models' in payload && payload.top_models?.length > 0)
                    || ('popular_areas' in payload && payload.popular_areas?.length > 0)
                    || ('job_types' in payload && payload.job_types?.length > 0)
                    || ('number_of_bikes' in payload && parseInt(payload.number_of_bikes?.replace(',', '') || '0', 10) > 0)
                    || ('number_of_scooters' in payload && parseInt(payload.number_of_scooters?.replace(',', '') || '0', 10) > 0)
                    || ('number_of_bicycles' in payload && parseInt(payload.number_of_bicycles?.replace(',', '') || '0', 10) > 0)
                    || ('number_of_items' in payload && parseInt(payload.number_of_items?.replace(',', '') || '0', 10) > 0)
                    || ('number' in payload && parseInt(payload.number?.replace(','), 10) || '0' > 0)
                    || ('models' in payload && parseInt(payload.models?.replace(',', '') || '0', 10) > 0)
                ) {
                    content.push({
                        ...(c?.list ? { list: c.list } : {}),
                        q: interpolate(c?.q, restProps.selectedLocation?.name, payload),
                        a: interpolate(c?.a, restProps.selectedLocation?.name, payload)
                    });
                }
            }
            else if (typeContent.type) {
                content.push({
                    ...(c?.list ? { list: c.list } : {}),
                    q: interpolate(c?.q, restProps.selectedLocation?.name, payload),
                    a: interpolate(c?.a, restProps.selectedLocation?.name, payload)
                });
            }
        }
    }

    const isLocationCityOrCountry = ['CITY', 'COUNTRY'].includes(restProps?.selectedLocation?.type);
    const shouldRenderFAQ = isLocationCityOrCountry && !isMultipleFilterSelected && (content?.length > 0);

    if (shouldRenderFAQ) {
        return <FAQ { ...({ content, id: parentCategoryId, title, track }) } />;
    }

    return <></>;
};

FAQWrapperV2.propTypes = {
    category: PropTypes.object,
    categoryFilters: PropTypes.object,
    faqContent: PropTypes.object,
    selectedFilters: PropTypes.array,
    getFaqContents: PropTypes.func.isRequired,
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired,
        formatNumber: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.shape({
        query: PropTypes.object,
        pathname: PropTypes.string
    }),
    parentCategoryId: PropTypes.string,
    selectedLocation: PropTypes.object,
    sessionFeatures: PropTypes.arrayOf(PropTypes.string),
    topModels: PropTypes.array,
    track: PropTypes.func
};

FAQWrapperV2.defaultProps = {
    params: {}
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    const { params: { categoryID }} = ownProps;

    return {
        getFaqContents: (type, content) => dispatch(getFaq(categoryID, type, content))
    };
};

const getParams = props => ({
    categoryId: props.params.categoryID,
    locationId: props.params.geoID,
    search: props.params.text,
    params: props.location.query,
    constructionStatus: props.params.constructionStatus,
    type: props.type || ''
});

export const mapStateToProps = (state, ownProps) => {
    const { config, params } = ownProps;
    const categoryId = params?.categoryID;

    const { categories, faq, locations } = state;

    const category = categories?.elements[categoryId];
    const categoriesInfo = categoriesByFilterSelector(state);
    const parentCategoryId = category?.parent_id === 0 ? category?.id : category?.parent_id;

    const filtersInfoByAttr = getAbundanceFilterInfo(state, categoryId, config);
    const appliedFiltersInfo = getParsedAppliedFilters(state, categoryId);

    const transformedFilterInfo = getFilteredNestedSelect(filtersInfoByAttr?.make || {}, filtersInfoByAttr, '', appliedFiltersInfo);
    const minPrice = getMinimumPrice(state);
    const plotSize = getPlotSize(state);

    return {
        category,
        // get to the number of ads for sub_categories.
        categoryFilters: categoriesInfo.find(cf => cf?.id === parentCategoryId),
        // get faq content from redux state to render on the UI.
        faqContent: faq?.elements[category?.id] || faq?.elements[parentCategoryId] || {},
        locations: getPopularLocationsLeftBar(state, getParams(ownProps)),
        // get the minimum price from the item list.
        minPrice,
        // the parent category id.
        parentCategoryId,
        plotSize,
        // get the list of selected filters.
        selectedFilters: getAppliedFiltersLabels(state, categoryId) || [],
        selectedLocation: locations?.selectedLocation,
        topModels: getTransformedMakeModels(transformedFilterInfo?.model || {})
    };
};

export { FAQWrapperV2 };

export default memo(compose(
    withSessionFeature,
    injectIntl,
    withRouter,
    withTrack,
    connect(mapStateToProps, mapDispatchToProps)
)(FAQWrapperV2));
