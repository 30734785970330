import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withRouter from 'HOCs/withRouter';
import PropTypes from 'prop-types';
import hoistNonReactStatic from 'hoist-non-react-statics';

import { addFiltersRecencyOrder } from 'Actions/filters';

import { getCategoryById, categoryIdSelector } from 'Selectors/categories';
import { locationByIdSelector } from 'Selectors/location';
import { getFiltersRecencyOrder } from 'Selectors/filtersTanak';

import { buildURL, buildObjectFromURL } from 'Helpers/url';
import { removeFilter, applyFilter, popFilter, appendFilter } from 'Helpers/filters';

import withConfig from 'HOCs/withConfig/withConfig';
import { isObject } from 'Helpers/objects';
import { ATTR_WITH_MAX_LIMIT } from 'Constants/filters';
import { itemsSearchQuerySelector } from 'Selectors/items';

const withFilterURL = WrappedComponent => {
    class WithFilterURL extends Component {
        static propTypes = {
            config: PropTypes.object.isRequired,
            location: PropTypes.object.isRequired,
            geoLocation: PropTypes.object,
            category: PropTypes.object,
            params: PropTypes.shape({
                text: PropTypes.string,
                categoryID: PropTypes.string,
                geoID: PropTypes.string
            }).isRequired,
            attribute: PropTypes.string,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.object,
                PropTypes.bool
            ]),
            rangeBounds: PropTypes.shape({
                minValue: PropTypes.number,
                maxValue: PropTypes.number
            }),
            appliedFiltersInfo: PropTypes.object,
            onTap: PropTypes.func,
            isChecked: PropTypes.bool,
            isMultiSelect: PropTypes.bool,
            filterInteractionType: PropTypes.string,
            addFiltersRecencyOrder: PropTypes.func.isRequired,
            filterRecencyOrder: PropTypes.arrayOf(PropTypes.string),
            searchQuery: PropTypes.string
        }

        static defaultProps = {
            appliedFiltersInfo: {},
            isChecked: false,
            isMultiSelect: false,
            rangeBounds: {},
            filterInteractionType: ''
        }

        constructor(props) {
            super(props);
            this.state = {
                to: this.getLinkUrl()
            };
        }

        componentDidUpdate(prevProps) {
            const {
                appliedFiltersInfo,
                category,
                geoLocation,
                location: { query },
                params: { text }
            } = this.props;

            if (prevProps.category !== category || prevProps.geoLocation !== geoLocation || prevProps.appliedFiltersInfo !== appliedFiltersInfo
                || prevProps.location.query !== query || prevProps.params.text !== text) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({
                    to: this.getLinkUrl()
                });
            }
        }

        getFiltersObj = () => {
            const { appliedFiltersInfo, attribute, value, rangeBounds, isChecked, isMultiSelect } = this.props;
            let newValue = {};

            if (isObject(value) && !ATTR_WITH_MAX_LIMIT[attribute]) {
                if (value.min && value.min !== rangeBounds?.minValue) {
                    newValue.min = value.min;
                }

                if (value.max && value.max !== rangeBounds?.maxValue) {
                    newValue.max = value.max;
                }
            }
            else {
                newValue = value;
            }

            if (isMultiSelect) {
                return (isChecked) ? popFilter(appliedFiltersInfo, attribute, value)
                    : appendFilter(appliedFiltersInfo, attribute, newValue);
            }
            return (isChecked) ? removeFilter(appliedFiltersInfo, attribute)
                : applyFilter(appliedFiltersInfo, { [attribute]: newValue });
        }

        getLinkUrl = () => {
            const {
                category,
                location: { query, pathname },
                config,
                searchQuery
            } = this.props;

            const urlObject = buildObjectFromURL(pathname);

            return buildURL({
                ...urlObject,
                category,
                params: {
                    ...query,
                    filter: this.getFiltersObj()
                },
                search: searchQuery
            }, {
                useFakeLevelSlug: config.get('SEO', 'useFakeLevelSlug')
            });
        }

        handleLinkClick = e => {
            e.preventDefault();
        }

        onTapOfOption = (...args) => {
            const {
                isMultiSelect,
                isChecked,
                filterRecencyOrder,
                appliedFiltersInfo,
                addFiltersRecencyOrder,
                attribute,
                value,
                filterInteractionType
            } = this.props;
            const newFilterRecencyOrder = filterRecencyOrder.filter(val => val !== attribute);

            if (!isChecked
                || (Array.isArray(appliedFiltersInfo[attribute]) && appliedFiltersInfo[attribute].length > 1)) {
                newFilterRecencyOrder.unshift(attribute);
            }

            const metaData = {
                url: this.state.to,
                filterAttribute: attribute,
                selectedValue: value,
                newFilters: this.getFiltersObj(),
                isDeselected: isChecked,
                filterInteractionType
            };

            addFiltersRecencyOrder(newFilterRecencyOrder);

            if (isMultiSelect) {
                this.props.onTap.apply(null, [...args, metaData]);
            }
            else {
                this.props.onTap(metaData);
            }
        }

        render() {
            return (<WrappedComponent
                { ...this.props }
                onTap={ this.onTapOfOption }
                to={ this.state.to }
                onLinkCLick={ this.handleLinkClick }
            />);
        }
    }

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    WithFilterURL.displayName = `withFilterURL(${wrappedComponentName})`;

    const mapStateToProps = (state, props) => {
        const categoryID = categoryIdSelector(state, props.params);

        return {
            filterRecencyOrder: getFiltersRecencyOrder(state),
            category: getCategoryById(state, categoryID),
            geoLocation: locationByIdSelector(state)(props.params.geoID),
            searchQuery: itemsSearchQuerySelector(state, props.params.text)
        };
    };

    const mapDispatchToProps = {
        addFiltersRecencyOrder
    };

    return hoistNonReactStatic(
        compose(
            withConfig,
            withRouter,
            connect(mapStateToProps, mapDispatchToProps)
        )(WithFilterURL),
        WrappedComponent
    );
};

export default withFilterURL;
