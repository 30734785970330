import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withRouter from 'HOCs/withRouter';
import { buildURL } from 'Helpers/url';
import withConfig from 'HOCs/withConfig/withConfig';
import { locationByIdSelector } from 'Selectors/location';
import { verifyUpdateLastListingUrl } from 'Actions/filtersPreferences';
import { DEFAULT_CATEGORY } from 'Constants/categories';
import { itemsSearchQuerySelector } from 'Selectors/items';

import loadable from '@loadable/component';

const NavigationBarMX = loadable(() => import(/* webpackChunkName: "listingMX" */'./NavigationBarMX'));
const NavigationBarOld = loadable(() => import(/* webpackChunkName: "listingOld" */'./NavigationBarOld'));

export class NavigationBar extends React.Component {
    static propTypes = {
        config: PropTypes.shape({
            get: PropTypes.func.isRequired
        }).isRequired,
        location: PropTypes.object.isRequired,
        params: PropTypes.shape({
            text: PropTypes.string,
            categoryID: PropTypes.string,
            geoID: PropTypes.string
        }).isRequired,
        category: PropTypes.object,
        geoLocation: PropTypes.object,
        verifyUpdateFiltersPreferences: PropTypes.func.isRequired,
        searchQuery: PropTypes.string
    };

    static defaultProps = {}

    constructor(props) {
        super(props);
        this.showMXDesign = props.config.get('adpvAuto', 'showMXDesign');
    }

    componentDidMount() {
        this.props.verifyUpdateFiltersPreferences(this.props.params, this.props.location.pathname);
    }

    componentDidUpdate() {
        this.props.verifyUpdateFiltersPreferences(this.props.params, this.props.location.pathname);
    }

    buildURL = filter => {
        const {
            category,
            geoLocation: location,
            location: { query },
            config,
            searchQuery
        } = this.props;

        return buildURL({
            category,
            location,
            params: {
                ...query,
                filter
            },
            search: searchQuery
        }, { useFakeLevelSlug: config.get('SEO', 'useFakeLevelSlug'), categoryCover: config.get('categoryCover') });
    }

    render() {
        const { location, category, params } = this.props;
        const selectedcategoryID = category && category.id ? category.id : DEFAULT_CATEGORY;
        const NavigationBar = this.showMXDesign ? NavigationBarMX : NavigationBarOld;

        return (<NavigationBar
            buildURL={ this.buildURL }
            location={ location }
            params={ params }
            selectedCategoryId={ selectedcategoryID }
        />);
    }
}

const mapStateToProps = (state, props) => ({
    geoLocation: locationByIdSelector(state)(props.params.geoID),
    searchQuery: itemsSearchQuerySelector(state, props.params.text)
});

const mapDispatchToProps = dispatch => ({
    verifyUpdateFiltersPreferences: (params, pathname) => dispatch(verifyUpdateLastListingUrl(params, pathname))
});

export default compose(
    withRouter,
    withConfig,
    connect(mapStateToProps, mapDispatchToProps)
)(NavigationBar);
