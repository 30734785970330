import React from 'react';
import { Link } from 'panamera-react-ui';
import { FACET_LABEL_TYPE } from 'Constants/filters';
import Checkbox from 'Components/ThematicCheckbox/ThematicCheckbox';
import PropTypes from 'prop-types';
import withFilterURL from 'HOCs/withFilterURL/withFilterURL';
import css from './LinkCheckbox.APP_TARGET.scss';
import { noop } from 'Helpers/function';

export const LinkCheckbox = ({
    to,
    className,
    name,
    onLinkCLick,
    id,
    isChecked,
    labelTitle,
    onTap,
    abundanceLabel,
    style,
    renderLabelAs,
    isDisabled
}) => {
    const labelAsText = renderLabelAs === FACET_LABEL_TYPE;
    const LabelContainer = labelAsText ? FACET_LABEL_TYPE : Link;

    const Label = (
        <LabelContainer
            className={ `${css.link} ${className}` }
            to={ labelAsText ? null : to }
            tabIndex={ labelAsText ? 0 : null }
            role={ labelAsText ? 'link' : null }
            onClick={ isDisabled ? undefined : onLinkCLick }
        >
            <span className={ css.labelEllipsis }>{ labelTitle }</span>
            {!!abundanceLabel && <span>{' '}{ abundanceLabel }</span>}
        </LabelContainer>
    );

    return (<Checkbox
        id={ id }
        value={ isChecked }
        label={ Label }
        labelTitle={ labelTitle }
        onChange={ isDisabled ? undefined : onTap }
        name={ name }
        disabled={ isDisabled }
        style={ style }
        inputClassName={ '' }
    />);
};

LinkCheckbox.propTypes = {
    className: PropTypes.string,
    to: PropTypes.string.isRequired,
    onLinkCLick: PropTypes.func,
    onTap: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isChecked: PropTypes.bool,
    labelTitle: PropTypes.string.isRequired,
    abundanceLabel: PropTypes.string,
    style: PropTypes.object,
    renderLabelAs: PropTypes.oneOf(['link', 'span']),
    isDisabled: PropTypes.bool
};

LinkCheckbox.defaultProps = {
    className: '',
    onLinkCLick: noop,
    abundanceLabel: '',
    renderLabelAs: 'link',
    isDisabled: false
};

export default withFilterURL(LinkCheckbox);
