import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Range from 'rc-slider/lib/Range';
import { formatPrice } from 'Helpers/numbers';
import { ATTR_NOT_TO_FORMAT, ATTR_WITHOUT_SUFFIX, PLUS_SUFFIX } from 'Constants/filters';
import css from './SliderInput.desktop.scss';
import { getLocale } from 'Selectors/config';
import { FormattedMessage as Translation } from 'react-intl';
import { Histogram } from 'panamera-react-ui';
import Button from 'Components/Button/Button';
import classNames from 'classnames';

export class SliderInput extends React.PureComponent {
    static propTypes = {
        range: PropTypes.shape({
            minValue: PropTypes.number.isRequired,
            maxValue: PropTypes.number.isRequired,
            step: PropTypes.number.isRequired
        }).isRequired,
        attribute: PropTypes.string.isRequired,
        selectedValues: PropTypes.shape({
            min: PropTypes.number.isRequired,
            max: PropTypes.number.isRequired
        }),
        onRangeChange: PropTypes.func.isRequired,
        locale: PropTypes.string.isRequired,
        histogramData: PropTypes.array
    }

    static defaultProps = {
        histogramData: []
    }

    constructor(props) {
        super(props);

        const { selectedValues = {}, range } = props;
        const { min: selectedMin, max: selectedMax } = selectedValues;
        const { minValue, maxValue } = range;

        this.state = {
            useUserInput: false,
            min: selectedMin || minValue,
            max: selectedMax || maxValue
        };
    }

    componentDidUpdate(prevProps) {
        const { selectedValues: { min: prevSelectedMin, max: prevSelectedMax } = {}} = prevProps;
        const {
            selectedValues: { min: selectedMin, max: selectedMax } = {},
            range: { minValue, maxValue }
        } = this.props;

        if ((prevSelectedMin !== selectedMin) || (prevSelectedMax !== selectedMax)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                min: selectedMin || minValue,
                max: selectedMax || maxValue
            });
        }
    }

    handleApply = () => {
        const {
            range: {
                minValue, maxValue
            }
        } = this.props;
        const newRange = {};

        const { min, max } = this.state;

        if (min > minValue) {
            newRange.min = min;
        }

        if (max < maxValue) {
            newRange.max = max;
        }
        this.props.onRangeChange(newRange);
    }

    shouldBtnBeDisabled = () => {
        const { selectedValues = {}, range } = this.props;
        const { min: selectedMin, max: selectedMax } = selectedValues;
        const { minValue, maxValue } = range;
        const { min, max } = this.state;

        const isMinUpdated = min !== (selectedMin || minValue);
        const isMaxUpdated = max !== (selectedMax || maxValue);
        const areBothZeroValue = min === minValue && max === minValue;

        return !(isMaxUpdated || isMinUpdated) || areBothZeroValue;
    }
    updateMinMaxValues = values => {
        const [newMin, newMax] = values;

        this.setState({ useUserInput: true, min: newMin, max: newMax });
    }

    render() {
        const {
            range: {
                minValue,
                maxValue,
                step
            },
            attribute,
            selectedValues,
            locale,
            histogramData
        } = this.props;

        const { useUserInput, min: userMin, max: userMax } = this.state;

        let selectedMinValue;
        let selectedMaxValue;

        if (useUserInput) {
            selectedMaxValue = userMax;
            selectedMinValue = userMin || 0;
        }
        else {
            const { min, max } = selectedValues || {};

            selectedMaxValue = max || maxValue;
            selectedMinValue = min || minValue;
        }

        const rangeValues = [selectedMinValue, selectedMaxValue];

        if (!ATTR_NOT_TO_FORMAT[attribute]) {
            const maxSuffix = ATTR_WITHOUT_SUFFIX[attribute] ? '' : PLUS_SUFFIX;

            selectedMinValue = formatPrice(selectedMinValue, locale);
            selectedMaxValue = selectedMaxValue === maxValue
                ? `${formatPrice(selectedMaxValue, locale)}${maxSuffix}`
                : formatPrice(selectedMaxValue, locale);
        }

        const shouldRenderHistogram = !!(histogramData && histogramData.length && histogramData.some(count => count > 0));
        const extraClass = shouldRenderHistogram ? '' : css.noHistogram;

        return (
            <div className={ css.sliderInputContainer }>
                <div className={ css.sliderInput }>
                    <div className={ classNames(css.toolTip, extraClass) } >
                        <span>{selectedMinValue}</span>
                        <span>{selectedMaxValue}</span>
                    </div>
                    <div className={ classNames(css.slider, extraClass) }>
                        <Range
                            value={ rangeValues }
                            allowCross={ true }
                            onChange={ this.updateMinMaxValues }
                            min={ minValue }
                            max={ maxValue }
                            step={ step }
                            className={ classNames(css.rangeSlider, extraClass) }
                        />
                        {shouldRenderHistogram && <div className={ css.histogramContainer }>
                            <Histogram data={ histogramData } />
                        </div>}
                    </div>
                </div>
                <Button
                    className={ css.applyBtn }
                    type="primary"
                    onClick={ this.handleApply }
                    disabled={ this.shouldBtnBeDisabled() }
                >
                    <Translation id="boostPackageAppy" />
                </Button>
            </div>
        );
    }
}

export const mapStateToProps = state => ({
    locale: getLocale(state)
});

export default connect(mapStateToProps)(SliderInput);
