import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { css as uicss } from 'panamera-react-ui';
import css from './ListingCarousel.APP_TARGET.scss';

const { icons } = uicss;

const CarouselArrows = ({ prevClick, nextClick }) => {
    return (
        <div>
            <span className={ classNames(css.arrows, css.prev, icons.panameraIcons, icons['icon-ArrowLeft']) } onClick={ prevClick } />
            <span className={ classNames(css.arrows, css.next, icons.panameraIcons, icons['icon-ArrowRight']) } onClick={ nextClick } />
        </div>
    );
};

CarouselArrows.propTypes = {
    prevClick: PropTypes.func,
    nextClick: PropTypes.func
};

CarouselArrows.defaultProps = {
    prevClick: () => {},
    nextClick: () => {}
};

export default CarouselArrows;
