import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import css from './Collapsible.scss';
import Icon from 'Components/ThematicIcon/ThematicIcon';

export class Collapsible extends React.Component {
  static propTypes = {
      children: PropTypes.element.isRequired,
      title: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.element
      ]).isRequired,
      collapsedContent: PropTypes.node,
      collapsed: PropTypes.bool,
      className: PropTypes.string,
      titleClassName: PropTypes.string,
      onCollapsed: PropTypes.func,
      'data-aut-id': PropTypes.string,
      isFiltersV2: PropTypes.bool,
      contentClassName: PropTypes.string,
      noTextTransform: PropTypes.bool
  };

  static defaultProps = {
      collapsed: false,
      'data-aut-id': 'collapsible',
      className: '',
      titleClassName: '',
      collapsedContent: null,
      onCollapsed: () => { },
      isFiltersV2: false,
      contentClassName: '',
      noTextTransform: false
  };

  toggleContent = () => this.props.onCollapsed(!this.props.collapsed);

  render() {
      const { title, titleClassName, children, collapsedContent, className, collapsed, 'data-aut-id': dataAutId, isFiltersV2, contentClassName, noTextTransform } = this.props;

      return (
          <div data-aut-id={ dataAutId } className={ classnames(css.collapsible, className) } >
              <div data-aut-id="collapsibleAction" className={ classnames(css.title, titleClassName, { [css.noTextTransform]: noTextTransform }) } onClick={ this.toggleContent }>
                  <span className={ css.text } data-aut-id={ `${dataAutId}_title` }>{title}</span>
                  <Icon
                      icon={ collapsed ? 'arrowDown' : 'arrowUp' }
                      size={ 18 }
                  />
              </div>
              {collapsedContent && (
                  <div data-aut-id="collapsedContent" className={ classnames(css.collapsedContent, { [css.collapsed]: collapsed, [css.tanakCollapsed]: isFiltersV2 }) }>
                      {collapsedContent}
                  </div>
              )}
              <div className={ classnames(css.content, contentClassName, {
                  [css.collapsed]: collapsed
              }) }>
                  {children}
              </div>
          </div>
      );
  }
}

export default Collapsible;
